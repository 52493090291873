<template>
    <div class="products-page page-font">
      <!-- Banner -->
      <div class="banner">
        <Banner :bannerList="bannerList"
                :scale="0.5"/>
        <VisibleBox direction="bottom" style="position: relative; z-index: 2">
          <h2>Products</h2>
        </VisibleBox>
      </div>

      <!-- 产品 -->
      <div class="about-products main" style="padding: 40px 0;">
        <!-- <Title title="our products"/> -->
        <VisibleBox>
          <ProductTabs :showPage="true" :hasBg="true" :border="true" v-model="category"/>
        </VisibleBox>
      </div>

      <!-- 服务说明 -->
      <!-- <div class="bg-light" v-if="baseInfo.service_desc">
        <div class="about-intro main" style="padding: 30px 0 50px 0">
          <Title title="Service Intro"/>
          <VisibleBox>
            <div class="intro-text" v-html="baseInfo.service_desc"></div>
          </VisibleBox>
        </div>
      </div> -->
    
      <!-- 合作伙伴 -->
      <div class="bg-light" id="partner">
        <div class="about-partner main" style="padding: 30px 0">
          <VisibleBox>
            <Title title="OUR STRONG BRAND"/>
            <Partners />
          </VisibleBox>
        </div>
      </div>

    </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Title from '../components/Title.vue'
import ProductTabs from '../components/ProductTabs.vue'
import VisibleBox from '../components/VisibleBox.vue'
import Partners from '../components/Partners.vue'

export default {
  name: 'Products',
  components: {
    Banner,
    Title,
    ProductTabs,
    Partners,
    VisibleBox
  },
  inject: ['getBaseInfo'],
  computed:{
    baseInfo(){
      return this.getBaseInfo()
    }
  },
  props: {
    
  },
  created(){
    
  },
  data(){
    return {
      bannerList: [
        {imgUrl: require(`../assets/products/product-banner-01.jpg`)}
      ],
      category: this.$route.query.category
    }
  },
  methods: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.products-page{
  .about-intro{
    width: 80%;
    .intro-text{
      // line-height: 26px;
      color: rgba(0,0,0,.7);
      // text-indent: 2em;
      ::v-deep p{
        font-size: 18px;
        margin: 1em 0;
      }
    }
  }
}
</style>
